<template>
  <div>
    <ros-status :ros="this.ros"></ros-status>
    <ros ref="rosObject" :url="this.rosIp" @getRosObject="setRos"></ros>
    <b-card>
      <robot-form v-on:getRobot="getRobot"></robot-form>
      <b-button @click="reconnection" variant="primary">再接続</b-button>
    </b-card>
    <b-row>
      <b-col>
        <b-card>
          <Monitoring v-bind:cpu="cpuInfo"></Monitoring>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ROSLIB from "roslib";
import Ros from "../components/Ros";
import RobotForm from "../components/form/RobotForm";
import RosStatus from "../components/RosStatus";
import Monitoring from "../components/Monitoring";
export default {
  components: {
    Ros,
    Monitoring,
    RobotForm,
    RosStatus
  },
  data() {
    return {
      cpuInfo: null,
      options: null,
      chartData: null,
      rosIp: null,
      ros: null
    };
  },
  methods: {
    getRobot(value) {
      this.rosIp = value.ip;
    },
    setRos(value) {
      this.ros = value;
      this.monitoring();
    },
    reconnection() {
      this.$refs.rosObject.init();
    },
    monitoring() {
      let _this = this;
      this.monitoringTopic = this.$refs.rosObject.createTopic("/monitoring");
      this.monitoringTopic.subscribe(function(message) {
        if (message.info[0].description == "A CPU-Monitor") {
          _this.cpuInfo = message.info[0].values;
        }
      });
    }
  },
  beforeDestroy() {
    if (this.ros) {
      this.ros.close();
    }
  }
};
</script>
