<template>
  <b-row>
    <b-col>
      <line-chart :chart-data="datacollection"></line-chart>
      <!-- <table>
        <tr :key="cpu.id" v-for="cpu in cpu" v-bind="cpu">
          <td>{{ cpu.errorlevel }}</td>
          <td>{{ cpu.value + cpu.unit }}</td>
          <td>{{ cpu.key }}</td>
        </tr>
      </table>-->
    </b-col>
  </b-row>
</template>

<script>
import LineChart from "../../components/LineChart";
export default {
  components: {
    LineChart
  },
  props: ["cpu"],
  data() {
    return {
      flag: false,
      datacollection: {},
      cpuData: [],
      datasets: [
        {
          label: "",
          backgroundColor: "",
          data: [],
          fill: false
        }
      ],
      backgroundColor: [
        "#CCFFCC",
        "#66FFFF",
        "#FFCC00",
        "#FF99FF",
        "#009900",
        "#FF0000",
        "#999999",
        "#FFFF00"
      ]
    };
  },
  mounted() {
    this.fillData();
  },
  watch: {
    cpu: {
      handler(newVal, oldVal) {
        if (!this.flag) {
          for (let i in this.cpu) {
            this.datasets.push({
              label: this.cpu[i].key,
              backgroundColor: this.backgroundColor[i],
              data: [],
              fill: false
            });
          }
          this.flag = true;
        }
        for (let i in this.cpu) {
          if (
            this.datacollection.labels.length <= this.datasets[i].data.length
          ) {
            this.datasets[i].data.shift();
          }
          this.datasets[i].data.push(this.cpu[i].value);
        }
        this.fillData();
      },
      deep: true
    }
  },
  methods: {
    fillData() {
      this.datacollection = {
        labels: [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          ""
        ],
        datasets: this.datasets
      };
    }
  }
};
</script>
